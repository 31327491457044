import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { gql, useQuery } from "@apollo/client"
import { Button, Empty, Skeleton } from "antd"
import ErrorScreen from "../../../components/ErrorScreen"
import {
  CERTIFICATION,
  DIPLOMA,
  MASTER,
} from "../../../enums/CertificationTypes"
import CertificationTile from "../../../components/CertificationTile"
import withCertificationState from "../../../components/withCertificationState"
import withTileBadgeCheck from "../../../components/withTileBadgeCheck"
import EventureGrid from "../../../components/EventureGrid"
import PageRoot from "../../../components/PageRoot"
import ContainerWithPadding from "../../../components/ContainerWithPadding"
import { devLog, getImageUrl, translatePath } from "../../../utils"
import { CertificationBadge } from "../../../components/Labels"
import CertificationBadgeCard from "../../../components/CertificationBadgeCard"
import { Link, navigate } from "gatsby"

const GET_USER_PURCHASED_CERTIFICATIONS_QUERY = gql`
  {
    CERTIFICATION: user_inventory_items(
      where: { certification: { type: { _eq: "CERTIFICATION" } } }
      distinct_on: item_id
    ) {
      certification {
        __typename
        id
        short_description
        subtitle
        title
        type
        badge_title
        badge {
          s3_key
        }
        exam {
          coolDown
        }
        seo {
          slug
        }
        landscape_locked {
          s3_key
        }
        landscape_success {
          s3_key
        }
      }
    }
    DIPLOMA: user_inventory_items(
      where: { certification: { type: { _eq: "DIPLOMA" } } }
      distinct_on: item_id
    ) {
      certification {
        __typename
        id
        short_description
        subtitle
        title
        type
        badge_title
        badge {
          s3_key
        }
        exam {
          coolDown
        }
        seo {
          slug
        }
        landscape_locked {
          s3_key
        }
        landscape_success {
          s3_key
        }
      }
    }
    MASTER: user_inventory_items(
      where: { certification: { type: { _eq: "MASTER" } } }
      distinct_on: item_id
    ) {
      certification {
        __typename
        id
        short_description
        subtitle
        title
        type
        badge_title
        badge {
          s3_key
        }
        exam {
          coolDown
        }
        seo {
          slug
        }
        landscape_locked {
          s3_key
        }
        landscape_success {
          s3_key
        }
      }
    }
    user_certification_states(where: { passed: { _eq: true } }) {
      certification {
        id
        badge {
          s3_key
        }
        badge_title
      }
      exam_submit_at
    }
  }
`

const Certification = () => {
  const { t } = useTranslation()
  const { data, loading, error } = useQuery(
    GET_USER_PURCHASED_CERTIFICATIONS_QUERY,
    {
      variables: { fetchPolicy: "network-only" },
    }
  )

  const content = useMemo(() => {
    if (loading) {
      return <Skeleton active round />
    }

    if (error) {
      return <ErrorScreen error={error} />
    }

    const renderItem = (item, index) => {
      const Component = withCertificationState(
        withTileBadgeCheck(CertificationTile)
      )

      return (
        <Component
          onClick={() => {
            navigate(`${translatePath("/online-tests")}/${item.seo.slug}`)
          }}
          data={item}
          extra={[
            <CertificationBadge badge={item.badge} title={item.badge_title} />,
          ]}
        />
      )
    }

    const renderBadge = (item, index) => {
      devLog({ item, index })
      const { certification, exam_submit_at } = item
      const cover = getImageUrl(certification.badge)
      return (
        <CertificationBadgeCard
          onClick={() => {
            navigate(`/go?id=${certification.id}`)
          }}
          key={index}
          title={certification.badge_title}
          image={cover}
        />
      )
    }

    const certifications = data[CERTIFICATION].map(
      ({ certification }) => certification
    )

    const diplomas = data[DIPLOMA].map(({ certification }) => certification)
    const masters = data[MASTER].map(({ certification }) => certification)

    devLog({ certifications, diplomas, masters })

    const totalCertificationsCount =
      certifications.length + diplomas.length + masters.length

    return (
      <ContainerWithPadding size="large">
        {totalCertificationsCount === 0 && (
          <>
            <Empty
              description={
                <>
                  <p>{t("label:noCertificationsAvailable")}</p>
                  <Button type="primary" className="purple">
                    <Link to={translatePath("/online-tests/all")}>
                      {t("label:discoverAllCertifications")}
                    </Link>
                  </Button>
                </>
              }
            />
          </>
        )}
        {certifications.length > 0 && (
          <EventureGrid
            title={t("label:certifications")}
            dataSource={certifications}
            renderItem={renderItem}
          />
        )}
        {diplomas.length > 0 && (
          <EventureGrid
            title={t("label:diplomas")}
            dataSource={data[DIPLOMA].map(({ certification }) => certification)}
            renderItem={renderItem}
          />
        )}
        {masters.length > 0 && (
          <EventureGrid
            title={t("label:masters")}
            dataSource={data[MASTER].map(({ certification }) => certification)}
            renderItem={renderItem}
          />
        )}
        {data.user_certification_states?.length > 0 && (
          <EventureGrid
            title={t("label:achievements")}
            dataSource={data.user_certification_states}
            renderItem={renderBadge}
            colSettings={{
              xs: 8,
              sm: 8,
              md: 4,
              lg: 3,
              xl: 2,
              xxl: 2,
            }}
          />
        )}
      </ContainerWithPadding>
    )
  }, [data, loading, error])

  return (
    <PageRoot title={t("label:myCertifications")} showTitle>
      {content}
    </PageRoot>
  )
}

export default Certification
